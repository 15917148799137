@mixin background($url, $position-x, $position-y) {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url($url) no-repeat $position-x $position-y;
}

html {
  @apply bg-neutral-900;
  &::before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;

    @include background("../public/images/background.dark.jpg", center, center);
    @media (max-width: 768px) and (max-height: 1080px) {
      @include background(
        "../public/images/background.dark.mobile.jpg",
        right,
        top
      );
    }
    background-size: cover;
  }
}

.load-in-enter {
  opacity: 0;
}
.load-in-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.load-in-exit {
  opacity: 1;
}
.load-in-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
