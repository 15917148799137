@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Poppins", sans-serif;
  @apply text-neutral-50;
  @apply antialiased;
  @apply scroll-smooth;
  font-display: block;
}

@import "~hamburgers/dist/hamburgers.min.css";
.hamburger {
  &:hover {
    opacity: unset !important;
  }
  .hamburger-inner {
    background-color: white !important;
    &::after,
    &::before {
      background-color: white !important;
    }
  }
}

$iconsfordevs-font-path: "~iconsfordevs/fonts";
@import "~iconsfordevs/css/iconsfordevs.scss";
@import "~devicon/devicon.min.css";
@import "~devicons/css/devicons.scss";
