main {
  section {
    min-height: 100vh;
    height: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      text-transform: capitalize;
      text-align: left;
      align-self: start;
      @apply text-2xl;
      @apply font-bold;
    }

    .section-component {
      margin: auto;
    }

    &:not(:first-child) {
      @apply border-t;
    }
  }
}
