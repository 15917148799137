.header-component {
  margin: 0.75rem auto;
  @media (min-height: 700px) {
    margin: 1.25rem auto;
  }
}

@media (max-width: 768px) {
  .menu {
    position: fixed;
    left: 100vw;
  }

  .menu.in {
    animation: slide-in 0.5s forwards;
  }

  .menu.out {
    animation: slide-out 0.5s forwards;
  }

  @keyframes slide-in {
    100% {
      left: 0;
    }
  }

  @keyframes slide-out {
    0% {
      left: 0;
    }
    100% {
      left: 100vw;
    }
  }
}
