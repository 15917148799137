.cursor {
  $cursor-color: white;

  padding-left: 0.1rem;
  color: $cursor-color;
  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: $cursor-color;
    }
  }

  animation: 1s blink step-end infinite;
}
