.interests {
  p {
    margin: 2rem 0;
  }

  .text-redskins {
    background-color: #5a1414;
    color: #ffb612;
  }

  .text-capitals {
    background-color: #041e42;
    color: #c8102e;
  }

  .text-nationals {
    background-color: #14225a;
    color: #ab0003;
  }

  .text-terps {
    background-color: #e03a3e;
    color: #ffd520;
  }

  .text-wizards {
    background-color: #002b5c;
    color: #e31837;
  }
}
