$md-breakpoint: 768px;
$divider-value: 50%;
$divider-value-md: 30%;

ul.timeline {
  li {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;

    &:not(:last-child) {
      padding-bottom: 1rem;
    }

    .timeline-left {
      width: $divider-value;
      padding-right: 25px;
      @media (min-width: $md-breakpoint) {
        width: $divider-value-md;
      }
      display: table-cell;
      min-height: 100%;
      text-align: right;
      vertical-align: top;
    }

    .timeline-divider {
      position: absolute;
      top: 0;
      @media (min-width: $md-breakpoint) {
        left: $divider-value-md;
      }
      bottom: 0;
      width: 1px;
      @apply bg-neutral-400;

      &::before {
        content: "";
        display: block;
        position: absolute;
        margin-top: 4px;
        width: 17px;
        height: 17px;
        position: absolute;
        margin-left: -8px;
        border-radius: 10px;
        @apply bg-neutral-300;
        opacity: 0.25;
        z-index: 0;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        margin-top: 8px;
        width: 9px;
        height: 9px;
        margin-left: -4px;
        @apply bg-neutral-300;
        border-radius: 5px;
        border: thin solid;
        @apply border-neutral-300;
        z-index: 1;
      }
    }

    .timeline-right {
      width: 70%;
      display: table-cell;
      padding-left: 25px;
      padding-right: 15px;
      vertical-align: top;
    }
  }
}
